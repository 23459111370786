/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 *
 * Root element declaration required for react-query implementation. See:
 *
 * See: https://www.paulie.dev/posts/2022/09/how-to-use-tanstack-query-with-gatsby/
 */

import React from 'react';
import RootElement from './src/components/root-element';

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};