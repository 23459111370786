/**
 *  Wraps Gatsby app in Query Client Provider as per https://www.paulie.dev/posts/2022/09/how-to-use-tanstack-query-with-gatsby/
 */

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const RootElement = ({ children }) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default RootElement;